import React, { FunctionComponent, Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import { SiteHeader } from './SiteHeader';
import { SiteFooter } from './SiteFooter';
import { Loading } from '../Loading';
import { ErrorBoundary } from '../ErrorBoundary';
import { PageRuntimeError } from '../PageRuntimeError';

import './index.scss';

type HeadProps = {
  title?: string
}

export type SiteProps = {
  wrapped?: boolean
} & HeadProps;

const SiteInner: FunctionComponent<React.PropsWithChildren<HeadProps>> = ({ title, children }) => {
  return (
    <div className="site">
      {title &&
        <Helmet>
          <title>{`${title} - GrahamHarper.net`}</title>
        </Helmet>}
      <SiteHeader className="site__header" />
      <main className="site__main">
        <ErrorBoundary fallback={<PageRuntimeError />}>
          <Suspense fallback={<Loading className="site__loading" />}>
            { children }
          </Suspense>
        </ErrorBoundary>
      </main>
      <SiteFooter className="site__footer" />
    </div>
  );
}

export const Site: FunctionComponent<React.PropsWithChildren<SiteProps>> = ({ wrapped = true, children, ...headProps }) => {
  const SiteWrapped = () => <SiteInner {...headProps} >{ children }</SiteInner>;
  return (
    wrapped ? (
      <div className="wrapper">
        <SiteWrapped />
      </div>
    ) : (
      <SiteWrapped />
    )
  );
};
