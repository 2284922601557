import React, { FunctionComponent, HTMLProps } from 'react';
import cn from 'classnames';
import { Spinner } from '../Spinner';

import './index.scss';

export const Loading: FunctionComponent<React.PropsWithChildren<HTMLProps<HTMLElement>>> = ({ className }) => {
  return (
    <div className={cn(className, 'loading')}>
      <div className="loading__text">Loading...</div>
      <div><Spinner /></div>
    </div>
  )
};
