import React from 'react';
import { PageError } from '../PageError';

import './index.scss';

export const PageNotFound = () => {
  return (
    <PageError>
      <section className="http-error">
        <p className="http-error__status-code">404</p>
        <p className="http-error__description">Not found.</p>
      </section>
    </PageError>
  );
};
