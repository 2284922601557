import React from 'react';
import { PageError } from '../PageError';

import './index.scss';

export const PageRuntimeError = () => {
  return (
    <PageError>
      <section className="runtime-error">
        <p className="runtime-error__uh-oh">Uh oh.</p>
        <p>Looks like I broke something.</p>
        <p>I'd really appreciate if you could <a href="mailto:bugreport@grahamharper.net">let me know</a> what just happened, so I can try to fix it.</p>
      </section>
    </PageError>
  );
};

export default PageRuntimeError;
