import React, { FunctionComponent } from 'react';

import './index.scss';

export const PageError: FunctionComponent<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <article className="page-error">
      {children}
    </article>
  );
};

export default PageError;
