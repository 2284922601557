import React, { FunctionComponent, HTMLProps } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { SocialMediaIcons } from  '../../SocialMediaIcons';

import './index.scss';

export const SiteHeader: FunctionComponent<React.PropsWithChildren<HTMLProps<HTMLElement>>> = ({ className }) => {
  return (
    <header className={cn(className, "header")}>
      <Link to="/" className="header__hero" title="Home">        
        <h1 className="header__title">GrahamHarper.net</h1>
      </Link>
      <nav className="header__navigation">
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/ltd">Contracting</Link></li>
          <li><Link to="/cv">CV</Link></li>
        </ul>
        <SocialMediaIcons className="header__social-icons" />
      </nav>
    </header>
  );
};
