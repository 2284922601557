import React, { lazy } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { PageNotFound } from './components/PageNotFound';
import { Site } from './components/Site';

import './App.scss';

const ContractingPage = lazy(() => import('./components/ContractingPage'));
const CurriculumVitae = lazy(() => import('./components/CurriculumVitae'));
const HomePage = lazy(() => import('./components/HomePage'));

export const App = () => {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <Site title="Home">
              <HomePage />
            </Site>
          </Route>
          <Route exact path="/ltd">
            <Site title="Contracting">
              <ContractingPage />
            </Site>
          </Route>
          <Route exact path="/cv">
            <Site title="Curriculum Vitae">
              <CurriculumVitae />
            </Site>
          </Route>
          <Route>
            <Site title="Page not found">
            <PageNotFound />
            </Site>
          </Route>
        </Switch>
      </BrowserRouter>
    </HelmetProvider>
  );
}
